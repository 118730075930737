<template>
    <div class="page-wrap">
        <div v-if="!showDel">
            <div class="top-card">
                <div class="input-group flex">
                    <div class="wrap flex">
                        <div class="label">任务名称：</div>
                        <div class="r-find">
                            <Input v-model="storePrams.title" placeholder="请输入任务名称" style="width: 250px"/>
                        </div>
                    </div>
                    <div class="wrap flex">
                        <div class="label">创建日期：</div>
                        <div class="r-find">
                            <DatePicker @on-change="changeTime" type="daterange" placement="bottom-end" placeholder="点击选择时间"
                                style="width: 250px"></DatePicker>
                        </div>
                    </div>
                    <div class="r-btn flex">
                        <Button  @click="searchList" type="primary">查询</Button>
                    </div>
                </div>
            </div>
            <div class="table-card">
                <Table :columns="columns" :data="data" :loading="loadingTab" >
                    <template slot-scope="{ row }" slot="operation">
                        <div class="flex-c">
                            <div class="table-btn del" @click="agree(row)">详情</div>
                            <div class="table-btn danger" @click="clearDel(row.id)">删除</div>
                        </div>
                    </template>
                    <template slot-scope="{ row }" slot="allTime">
                        <div style="padding:10px 0">
                            <div>{{ row.start_time }}</div>
                            <div>-</div>
                            <div>{{ row.end_time }}</div>
                        </div>
                    </template>
                    <template slot-scope="{ row }" slot="effectiveTime">
                        <div v-if="row.start_expired == null ||row.start_expired == undefined ||row.start_expired == '' || row.start_expired =='0000-00-00'">暂无日期</div>
                        <div v-else>
                            <div>{{ row.start_expired }}</div>
                            <div>-</div>
                            <div>{{ row.end_expired }}</div>
                        </div>
                    </template>                
                    <template slot-scope="{ row }" slot="allPerson">
                        <div>{{ row.completed_num }}/{{ row.support_num }}</div>
                    </template>
                    <template slot-scope="{row}" slot="is_requirement">
                        <div  v-if="row.requirement == 0" >个人</div>
                        <div  v-if="row.requirement == 1" >企业</div>
                        <div  v-if="row.requirement == 2" >个人、企业</div>
                    </template>
                    <template slot-scope="{row}" slot="is_sms">
                        <div  v-if=" row.smsStatus == 1"  style="color:#54CDC9" >启用</div>
                        <div  v-else >未启用</div>
                    </template>
                    <template slot-scope="{row}" slot="sign_status">
                        <div v-if=" row.is_nail_sign == 1" class="table-link">已签署</div>
                        <div v-else class="table-link" style="color:#ff0000">未签署</div>
                    </template>
                    <template slot-scope="{row}" slot="support_yinzhang">
                        <div v-if=" row.support_yinzhang == 1"  style="color:#54CDC9">启用</div>
                        <div v-else class="table-link">未启用</div>
                    </template>
                    <template slot-scope="{row}" slot="showBtn">
                        <div class="flex-c">
                            <div class="table-btn del" @click="seePic(row)">查看</div>
                        </div>
                    </template>
                </Table>
                <Page :total="storePrams.total"
                    :current="storePrams.page"
                    style="text-align:right;margin-top: 20px"
                    show-elevator
                    show-total
                    @on-change="pageChange"
                    :page-size="storePrams.limit"/>
            </div>
        </div>
        <div v-if="seeCard">
            <div class="pop"></div>
            <div class="pop-card fixed flex-c">
                <div class="l-pop-del">
                    <div class="pop-pic">
                        <img :src="leftImg"/>
                    </div>
                    <div class="pop-word">参与扫码签署</div>
                </div>
                <div class="r-pop-del">
                    <div class="pop-pic">
                        <img :src="rightImg"/>
                    </div>
                    <div class="pop-word">其他人扫码签署</div>
                </div>
                <div class="pos-btn flex-c" @click="seeCard = false">关闭</div>
            </div>
        </div>
        <div v-if="seeTable">
            <div class="pop"></div>        
            <div class="pop-table fixed">    
                <div class="contxq">合同详情</div>
                <div style="padding:24px;padding-top:0px">
                    <div class="search-group flex">
                        <div class="wrap flex">
                            <div class="label">任务名称：</div>
                            <div class="r-find">
                            <Input v-model="elsePrams.title" placeholder="请输入任务名称" style="width: 240px"/>
                            </div>
                        </div>
                        <div class="r-btn flex" style="margin-left:12px">
                            <Button  @click="getDetail" type="primary">查询</Button>
                        </div>
                    </div>
                    <Table :columns="columnTable" :data="dataTable" :loading="loadingTabA">
                        <template slot-scope="{row}" slot="operation" >
                            <div class="table-link" @click="seeContractDel(row)">查看</div>
                        </template>
                        <template slot-scope="{row}" slot="name">
                            <!-- class="table-link" -->
                            <div>{{ row.part.name }}</div>
                        </template>
                        <template slot-scope="{row}" slot="phone">
                            <!-- class="table-link" -->
                            <div >{{ row.part.phone }}</div>
                        </template>
                    </Table>
                    <Page :total="elsePrams.total"
                        :current="elsePrams.page"
                        style="text-align:right;margin-top: 20px"
                        show-elevator
                        show-total
                        @on-change="pageChanges"
                        :page-size="elsePrams.limit"/>
                </div>                
                <div class="close-icon" @click="seeTable = false">
                    <Icon type="md-close" size="24"/>
                </div>
            </div>
        </div>
        <detail ref="contractDel" @goBack="showSelf"></detail>
    </div>
</template>

<script>
import detail from './components/contractDel'
import {batchList, batchDetails , delBatch,} from '../../api/interFace'
export default {
    name: "contract",
    components:{
        detail
    },
    data() {
        return {
            fromVal: {
                contract_name: '',
                start_time: '',
                end_time: '',
            },
            loadingTab:false,
            loadingTabA:false,
            columns: [
                {
                    title: '#',
                    align: 'center',
                    width: 120,
                    key: 'id'
                },
                {
                    title: '任务名称',
                    align: 'center',
                    width: 200,
                    key: 'task_name'
                },
                {
                    title: '签约截止日期',
                    align: 'center',
                    slot: 'allTime',
                    width:210
                },
                {
                    title: '合同有效日期',
                    align: 'center',
                    slot: 'effectiveTime',
                    width:180
                },
                {
                    title: '二维码',
                    key: 'address',
                    align: 'center',
                    width: 120,
                    slot: 'showBtn'
                },
                {
                    title: '参与人数',
                    slot: 'allPerson',
                    width: 120,
                    align: 'center'
                },
                {
                    title: '创建日期',
                    align: 'center',
                    width: 210,
                    key: 'created_at'
                },
                {
                    title: '甲方状态',
                    key: 'action',
                    width: 100,
                    align: 'center',
                    slot: 'sign_status'
                },
                {
                    title: '签署者类型',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    slot: 'is_requirement'
                },
                {
                    title: '短信验证',
                    key: 'action',
                    width: 100,
                    align: 'center',
                    slot: 'is_sms'
                },
                {
                    title: '企业印章',
                    key: 'action',
                    width: 100,
                    align: 'center',
                    slot: 'support_yinzhang'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 210,
                    align: 'center',
                    fixed: 'right',
                    slot: 'operation'
                }
            ],
            data: [],
            columnTable: [
                {
                    title: '合同ID',
                    align: 'center',
                    width: 120,
                    key: 'id'
                },
                {
                    title: '签署者',
                    align: 'center',
                    slot: 'name'
                },
                {
                    title: '手机号',
                    align: 'center',
                    slot: 'phone',
                },
                {
                    title: '签署详情',
                    align: 'center',
                    // width: 120,
                    key: 'sign_time'
                },
                {
                    title: '合同详情',
                    key: 'action',
                    align: 'center',
                    slot: 'operation'
                }
            ],
            dataTable: [],
            showDel: false,
            seeCard: false,
            seeTable: false,
            leftImg:'',
            rightImg:'',
            storePrams: {
                limit: 10,
                page: 1,
                total: 0,
                start_time: '',
                end_time: '',
                title: ''
            },
            elsePrams: {
                limit: 10,
                page: 1,
                total: 0,
                title: '',
                batch_id: '',
            },
        }
    },
    created() {
        this.$store.commit('changePollingSpin', false)
        this.getList()
    },
    mounted() {

    },
    methods: {
        searchList(){
            this.storePrams.page = 1
            this.getList()
        },    
        getList() {
            this.loadingTab = true
            batchList(this.storePrams).then(res => {
                this.loadingTab = false
                this.data = res.data.data || []
                this.storePrams.total = res.data.total
            }).catch(res => {
                this.loadingTab = false
                console.log(res)
            })
        },
        seeContractDel(row){
            this.$router.push({ path: '/management/contract',query: row})  
            // console.log('row',row)
            // const msg = this.$Message.loading({
            //   content: 'Loading...',
            //   duration: 0
            // });
            // contractDetails({id: row.id,pcStatus:1}).then(res => {
            //   this.showDel = true
            //   this.seeTable = false
            //   this.$nextTick(()=>{
            //     this.$refs.contractDel.showDetail(res)
            //   })
            //   setTimeout(msg, 100);
            // }).catch(res => {
            //   this.$Message.error(res.msg)
            // })
        },
        pageChange(index) {
            this.storePrams.page = index
            this.getList()
        },
        agree(e) {
            this.elsePrams.batch_id = e.id
            this.elsePrams.page = 1
            this.searchMore()
        },
        getDetail(){
            this.elsePrams.page = 1
            this.searchMore()
        },
        searchMore(){
            this.loadingTabA = true
            batchDetails(this.elsePrams).then(res => {
                this.loadingTabA = false
                this.seeTable = true
                this.dataTable = res.data.data || []
                this.elsePrams.total = res.data.total
            }).catch(res=>{
                this.loadingTabA = false
                this.$Message.error(res.msg)
            })
        },
        changeTime(e) {
            this.storePrams.start_time = e[0]
            this.storePrams.end_time = e[1]
        },
        seePic(row){
            this.seeCard = true
            this.leftImg=row.firstparty_code_url
            this.rightImg=row.partyb_code_url
            console.log(row)
        },
        showSelf(){
            this.showDel = false
        },
        clearDel(id) {
            this.$Modal.confirm({
                title: '删除提示',
                content: '请问您是否确认删除此条签署任务，删除后无法恢复!!!',
                onOk: () => {
                    delBatch({batch_id:id}).then(res=>{
                        this.$Message.success(res.msg)
                        this.getList()
                    }).catch(res=>{
                        this.$Message.error(res.msg)
                    })
                },
                onCancel: () => {
                }
            });
        },
        pageChanges(index) {
            this.elsePrams.page = index
            this.searchMore()
        },
    }
}
</script>

<style scoped lang="scss">
.page-wrap {  
    .top-card {
        width: 100%;
        background: #FFFFFF;        
        border-radius: 8px;
        padding: 20px 20px;
        box-sizing: border-box;
        .input-group {
            .wrap {
                margin-right: 25px;
            }
            .label {
                font-size: 14px;
                color: #545F78;
                .iconfont {
                    color: #4877E8;
                    font-size: 18px;
                }
            }
            .r-btn {
                .s-btn {
                    width: 78px;
                    height: 30px;
                    background: #4877E8;
                    border-radius: 15px;
                    font-size: 12px;
                    font-weight: bold;
                    color: #FFFFFF;
                    cursor: pointer;
                    margin-right: 20px;
                    &:hover {
                        opacity: .4;
                    }
                }
                .d-btn {
                    width: 78px;
                    height: 30px;
                    background: #F77F54;
                    border-radius: 15px;
                    font-size: 12px;
                    font-weight: bold;
                    color: #FFFFFF;
                    cursor: pointer;          
                    &:hover {
                        opacity: .4;
                    }
                }
            }
        }
    }
    .table-card {
        width: 100%;
        min-height: 700px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 20px 20px;
        box-sizing: border-box;
        margin-top: 20px;
    }
    .pop-card {
        width: 712px;
        height: 420px;
        background: #FFFFFF;
        border-radius: 5px;
        z-index: 999;
        .l-pop-del, .r-pop-del {
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            justify-content: center;
        }
        .pop-pic {
            width: 180px;
            height: 180px;
            border-radius: 6px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }
        }
        .pop-word {
            font-size: 16px;
            color: #191A1F;
            margin-top: 20px;
        }
        .pos-btn {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 30px;
            width: 88px;
            height: 34px;
            background: #FFFFFF;
            font-weight: bold;
            border: 1px solid #DBDEE6;
            border-radius: 6px;
            cursor: pointer;
            color: #191A1F;
            &:hover {
                box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.1);
            }
        }
    }
    .pop-table {
        width: 900px;
        background: #FFFFFF;
        border-radius: 5px;
        box-sizing: border-box;
        z-index: 999;
        .search-group {
            margin-bottom: 20px;
            .wrap {
                .label {
                    font-size: 14px;
                    color: #545F78;
                }
            }
            .s-btn {
                width: 78px;
                height: 30px;
                background: #4877E8;
                border-radius: 15px;
                font-size: 12px;
                font-weight: bold;
                color: #FFFFFF;
                cursor: pointer;
                margin-right: 20px;
                margin-left: 20px;
                &:hover {
                    opacity: 0.4;
                }
            }
        }

        .table-link {
            font-size: 15px;
            font-weight: bold;
            color: #4877E8;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
        .close-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 10px;
            cursor: pointer;
        }
        .contxq {
            font-size: 16px;
            margin-top: 16px;
            // margin-left: 16px;
            margin-bottom: 16px;
            font-weight: 500;
            color:#17233d;
            padding-left: 16px;
            padding-bottom: 8px;
            border-bottom:1px solid #e8eaec;
        }
    }
}

</style>
