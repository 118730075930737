var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[(!_vm.showDel)?_c('div',[_c('div',{staticClass:"top-card"},[_c('div',{staticClass:"input-group flex"},[_c('div',{staticClass:"wrap flex"},[_c('div',{staticClass:"label"},[_vm._v("任务名称：")]),_c('div',{staticClass:"r-find"},[_c('Input',{staticStyle:{"width":"250px"},attrs:{"placeholder":"请输入任务名称"},model:{value:(_vm.storePrams.title),callback:function ($$v) {_vm.$set(_vm.storePrams, "title", $$v)},expression:"storePrams.title"}})],1)]),_c('div',{staticClass:"wrap flex"},[_c('div',{staticClass:"label"},[_vm._v("创建日期：")]),_c('div',{staticClass:"r-find"},[_c('DatePicker',{staticStyle:{"width":"250px"},attrs:{"type":"daterange","placement":"bottom-end","placeholder":"点击选择时间"},on:{"on-change":_vm.changeTime}})],1)]),_c('div',{staticClass:"r-btn flex"},[_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.searchList}},[_vm._v("查询")])],1)])]),_c('div',{staticClass:"table-card"},[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.data,"loading":_vm.loadingTab},scopedSlots:_vm._u([{key:"operation",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-c"},[_c('div',{staticClass:"table-btn del",on:{"click":function($event){return _vm.agree(row)}}},[_vm._v("详情")]),_c('div',{staticClass:"table-btn danger",on:{"click":function($event){return _vm.clearDel(row.id)}}},[_vm._v("删除")])])]}},{key:"allTime",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"padding":"10px 0"}},[_c('div',[_vm._v(_vm._s(row.start_time))]),_c('div',[_vm._v("-")]),_c('div',[_vm._v(_vm._s(row.end_time))])])]}},{key:"effectiveTime",fn:function(ref){
var row = ref.row;
return [(row.start_expired == null ||row.start_expired == undefined ||row.start_expired == '' || row.start_expired =='0000-00-00')?_c('div',[_vm._v("暂无日期")]):_c('div',[_c('div',[_vm._v(_vm._s(row.start_expired))]),_c('div',[_vm._v("-")]),_c('div',[_vm._v(_vm._s(row.end_expired))])])]}},{key:"allPerson",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.completed_num)+"/"+_vm._s(row.support_num))])]}},{key:"is_requirement",fn:function(ref){
var row = ref.row;
return [(row.requirement == 0)?_c('div',[_vm._v("个人")]):_vm._e(),(row.requirement == 1)?_c('div',[_vm._v("企业")]):_vm._e(),(row.requirement == 2)?_c('div',[_vm._v("个人、企业")]):_vm._e()]}},{key:"is_sms",fn:function(ref){
var row = ref.row;
return [( row.smsStatus == 1)?_c('div',{staticStyle:{"color":"#54CDC9"}},[_vm._v("启用")]):_c('div',[_vm._v("未启用")])]}},{key:"sign_status",fn:function(ref){
var row = ref.row;
return [( row.is_nail_sign == 1)?_c('div',{staticClass:"table-link"},[_vm._v("已签署")]):_c('div',{staticClass:"table-link",staticStyle:{"color":"#ff0000"}},[_vm._v("未签署")])]}},{key:"support_yinzhang",fn:function(ref){
var row = ref.row;
return [( row.support_yinzhang == 1)?_c('div',{staticStyle:{"color":"#54CDC9"}},[_vm._v("启用")]):_c('div',{staticClass:"table-link"},[_vm._v("未启用")])]}},{key:"showBtn",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-c"},[_c('div',{staticClass:"table-btn del",on:{"click":function($event){return _vm.seePic(row)}}},[_vm._v("查看")])])]}}],null,false,4274592553)}),_c('Page',{staticStyle:{"text-align":"right","margin-top":"20px"},attrs:{"total":_vm.storePrams.total,"current":_vm.storePrams.page,"show-elevator":"","show-total":"","page-size":_vm.storePrams.limit},on:{"on-change":_vm.pageChange}})],1)]):_vm._e(),(_vm.seeCard)?_c('div',[_c('div',{staticClass:"pop"}),_c('div',{staticClass:"pop-card fixed flex-c"},[_c('div',{staticClass:"l-pop-del"},[_c('div',{staticClass:"pop-pic"},[_c('img',{attrs:{"src":_vm.leftImg}})]),_c('div',{staticClass:"pop-word"},[_vm._v("参与扫码签署")])]),_c('div',{staticClass:"r-pop-del"},[_c('div',{staticClass:"pop-pic"},[_c('img',{attrs:{"src":_vm.rightImg}})]),_c('div',{staticClass:"pop-word"},[_vm._v("其他人扫码签署")])]),_c('div',{staticClass:"pos-btn flex-c",on:{"click":function($event){_vm.seeCard = false}}},[_vm._v("关闭")])])]):_vm._e(),(_vm.seeTable)?_c('div',[_c('div',{staticClass:"pop"}),_c('div',{staticClass:"pop-table fixed"},[_c('div',{staticClass:"contxq"},[_vm._v("合同详情")]),_c('div',{staticStyle:{"padding":"24px","padding-top":"0px"}},[_c('div',{staticClass:"search-group flex"},[_c('div',{staticClass:"wrap flex"},[_c('div',{staticClass:"label"},[_vm._v("任务名称：")]),_c('div',{staticClass:"r-find"},[_c('Input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入任务名称"},model:{value:(_vm.elsePrams.title),callback:function ($$v) {_vm.$set(_vm.elsePrams, "title", $$v)},expression:"elsePrams.title"}})],1)]),_c('div',{staticClass:"r-btn flex",staticStyle:{"margin-left":"12px"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.getDetail}},[_vm._v("查询")])],1)]),_c('Table',{attrs:{"columns":_vm.columnTable,"data":_vm.dataTable,"loading":_vm.loadingTabA},scopedSlots:_vm._u([{key:"operation",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table-link",on:{"click":function($event){return _vm.seeContractDel(row)}}},[_vm._v("查看")])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.part.name))])]}},{key:"phone",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.part.phone))])]}}],null,false,653052538)}),_c('Page',{staticStyle:{"text-align":"right","margin-top":"20px"},attrs:{"total":_vm.elsePrams.total,"current":_vm.elsePrams.page,"show-elevator":"","show-total":"","page-size":_vm.elsePrams.limit},on:{"on-change":_vm.pageChanges}})],1),_c('div',{staticClass:"close-icon",on:{"click":function($event){_vm.seeTable = false}}},[_c('Icon',{attrs:{"type":"md-close","size":"24"}})],1)])]):_vm._e(),_c('detail',{ref:"contractDel",on:{"goBack":_vm.showSelf}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }